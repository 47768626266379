import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Msal from "msal";
import { GraphService } from "./GraphService";
import { FocusZone, FocusZoneDirection } from 'office-ui-fabric-react/lib/FocusZone';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { List } from 'office-ui-fabric-react/lib/List';
import { ITheme, mergeStyleSets, getTheme, getFocusStyle } from 'office-ui-fabric-react/lib/Styling';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { LoadingSpinner } from "./LoadingSpinner";
import {
    PrimaryButton,
    DefaultButton,
    FontIcon,
    IColumn,
    SearchBox, ISearchBoxStyles,
    Label,
    Stack, IStackStyles, IStackItemStyles,
    Checkbox
} from 'office-ui-fabric-react';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    DetailsRow,
    IDetailsRowProps,
    IDetailsRowStyles,
    DetailsHeader,
    IDetailsHeaderProps,
    IDetailsHeaderStyles
} from 'office-ui-fabric-react/lib/DetailsList';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import { IRenderFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react/lib/ScrollablePane';
import { NewShareForm } from '../Forms/NewShareForm';
import { AIPConfirmForm } from '../Forms/AIPConfirmForm';
import { CleanShareRequestForm } from '../Forms/CleanShareRequestForm';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { ShareListMenu } from './ShareListMenu';
import { colorThemeDark, colorThemeLight } from './Themes';
import * as moment from 'moment';
import 'moment/locale/pl';
import { ManageDARequestForm } from "../Forms/ManageDARequestForm";

initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/");

const config = require("./config.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");


export interface ShareListProps {
    shares?: IShareItem[],
    authToken: string,
    disabled?: boolean,
    checked?: boolean,
    isAdmin: boolean,
    isOperator: boolean,
    userName: string,
    lang: string,
    onFileShareClick(shareName: string, shareGroupId: string): void;
};

export interface ShareListState {
    shares?: IShareItem[],
    shareRequests?: IShareItem[],
    adminShares?: IShareItem[],
    adminShareItems?: IShareItem[],
    adminUserItems?: IUserItem[],
    directAccessRequests?: IDirectAccessItem[],
    columns: IColumn[],
    userColumns: IColumn[],
    loadingState: boolean,
    loadingStateAdmin: boolean,
    loadingStateShareItems: boolean,
    loadingStateRequests: boolean,
    loadingStateUserItems: boolean,
    showNewShareModal: boolean,
    showAIPModal: boolean,
    currentItem?: any[],
    noAdminSharesLoaded?: string,
    userIsAdmin?: boolean,
    itemCount: number,
    lang: any,
    announcedMessage?: string;
    searchBoxUserValue?: string;
    searchBoxShareValue?: string;
    notactiveCheckBox: boolean;
    aipCheckBox: boolean;
    exAccCheckBox: boolean;
    inAccCheckBox: boolean;
    deactivatedShares?: number;
    aipShares?: number;
    activeShares?: number;
    externalUsers?: number;
    internalUsers?: number;
    showDirectAccessModal: boolean;
    directAccessItem?: IDirectAccessItem;
    showCleanModal: boolean;
};

interface IShareItem {
    shareName: string;
    shareGroupId?: string;
    shareSiteId?: string;
    createdDateTime?: Date;
    aipToggle: boolean;
    requestStatus: string;
    shareItemId: string;
    owner?: string;
    shareState?: string;
    requestCreator: string;
    created?: Date;
    shareType?: string;
    deactivated?: string;
    closureDate?: string;
    closureDateValue?: number;
    shareSize?: string;
    shareSizeRaw?: number;
    shareUsage?: string;
    shareUsageRaw?: number;
    shareUsageRawPrcnt?: number;
    warningTrigger?: boolean;
    closeTrigger?: boolean;
    deleteDate?: Date;
    requestDate?: Date;
}

interface IUserItem {
    upn: string;
    email: string;
    isOperator: boolean;
    shareName: string;
    userType: string;
    firstName: string;
    lastName: string;
    alert: string;
    created: Date;
    groupId: string;
}

interface IDirectAccessItem {
    email: string;
    shareName: string;
    status: string;
    approveDate: string;
    shareURL: string;
    justification: string;
    requestItemID: number;
}

interface IIShareItemListClassObject {
    itemCell: string;
    itemStaticCell: string;
    itemContent: string;
    itemName: string;
    itemHeader: string;
    requestItemContent: string;
    ownerName: string;
    //chevron: string;
}

const theme: ITheme = getTheme();
const { fonts } = theme;

const basicClasses = mergeStyleSets({
    genericIcon: {
        paddingLeft: '1px',
        paddingRight: '1px',
        color: colorThemeLight.palette.themePrimary,
        marginLeft: '2px',
    },
    wrnIcon: {
        paddingLeft: '3px',
        paddingRight: '1px',
        color: '#bdad7d',
        verticalAlign: 'top'
    },
    errIcon: {
        paddingLeft: '3px',
        paddingRight: '1px',
        color: 'red',
        verticalAlign: 'top'
    },
    adminList: {
        height: '50vh',
        position: 'relative',
        cursor: 'pointer'
    },
    compactCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    }
})

const shareFilterStyles: IStackStyles = {
    root: {
        background: colorThemeLight.palette.neutralLight,
        marginTop: '8px',
        overflow: 'hidden',
    }
}

const shareSearchBoxStyles: IStackItemStyles = {
    root: {
        background: colorThemeLight.palette.neutralLight,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        width: 300,
        marginRight: 40
    },
};

const shareFiterItemStyles: IStackItemStyles = {
    root: {
        background: colorThemeLight.palette.neutralLight,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        marginRight: 20
    },
};

const searchBoxStyle: ISearchBoxStyles = {
    root: {
        width: 'inherit'
    }
}


const stackTokens = { childrenGap: 10 };

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(colorThemeLight, { inset: -1 }),
        {
            minHeight: 48,
            padding: 10,
            boxSizing: 'border-box',
            marginBottom: '6px',      //borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            // borderBottom: `3px solid rgb(190, 200, 200)`, 
            borderBottom: `${"2px solid"} ${colorThemeLight.palette.neutralPrimary}`,
            display: 'flex',
            selectors: {
                //'&:hover': { background: palette.neutralLight }
                '&:hover': { background: colorThemeLight.palette.neutralLight }
            },
            cursor: "pointer"
        }
    ],
    itemStaticCell: [
        getFocusStyle(colorThemeLight, { inset: -1 }),
        {
            minHeight: 48,
            padding: 10,
            boxSizing: 'border-box',
            marginBottom: '6px',
            borderBottom: `${"2px solid"} ${colorThemeLight.palette.neutralPrimary}`,
            display: 'flex'
            //cursor: "pointer"
        }
    ],
    itemHeader: {
        minHeight: 40,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 20,
        paddingRight: 10,
        boxSizing: 'border-box',
        marginBottom: '6px',
        borderBottom: `${"4px solid"} ${colorThemeLight.palette.neutralPrimary}`,
        display: 'flex',
        backgroundColor: colorThemeLight.palette.themePrimary, //'#828282',
        color: 'white'
    },
    itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between'
    },
    requestItemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        //justifyContent: 'start'
    },
    itemName: [
        fonts.medium,
        {
            // whiteSpace: 'nowrap',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center'
        }
    ],
    ownerName: [
        fonts.mediumPlus,
        {
            // whiteSpace: 'nowrap',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            minWidth: '150px',
            justifyContent: 'flex-end'
        }
    ]
});


export class ShareList extends React.Component<ShareListProps, ShareListState> {
    private msalConfig: Msal.Configuration;
    private userAgentApplication: Msal.UserAgentApplication;
    private _shareItems: IShareItem[];
    private _sharesFiltered: IShareItem[];
    private _adminShareItems: IShareItem[];
    private _adminUserItems: IUserItem[];
    private _shareRequests: IShareItem[];
    private _directAccessRequests: IDirectAccessItem[];
    private newShareForm = React.createRef<NewShareForm>();
    private aipConfirmForm = React.createRef<AIPConfirmForm>();
    private cleanShareRequestForm = React.createRef<CleanShareRequestForm>();
    private manageDARequestForm = React.createRef<ManageDARequestForm>();

    constructor(props: ShareListProps) {
        super(props);

        this._sharesFiltered = [];
        // this._onShareRequestClick = this._onShareRequestClick.bind(this);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Nazwa obszaru',
                fieldName: 'shareName',
                minWidth: 340,
                maxWidth: 340,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    return (
                        <span onClick={() => { this.props.onFileShareClick(item.shareName, item.shareGroupId) }}>{item.shareName}</span>
                    );
                }
            },
            {
                key: 'column2',
                name: 'AIP',
                fieldName: 'aipToggle',
                minWidth: 20,
                maxWidth: 20,
                isRowHeader: true,
                isIconOnly: false,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                //data: 'string',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    return (
                        <span>
                            {item.aipToggle && <FontIcon iconName="LockSolid" className={basicClasses.genericIcon} />}
                        </span>
                    );
                }
            },
            {
                key: 'column3',
                name: 'Utworzony przez',
                fieldName: 'owner',
                minWidth: 140,
                maxWidth: 140,
                isRowHeader: true,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    return (
                        <span>{item.owner}</span>
                    );
                }
            },
            {
                key: 'column4',
                name: 'Data wyłączenia',
                fieldName: 'closureDateValue',
                minWidth: 110,
                maxWidth: 110,
                isRowHeader: true,
                isResizable: false,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    return (
                        <span>
                            {item.deactivated == "1" ?
                                <TooltipHost
                                    content={`${gblResourcePL.str_TipShareDeleted} ${item.deleteDate.toLocaleDateString("pl-pl")}`}
                                    id="1"
                                    calloutProps={{ gapSpace: 0 }}
                                    styles={{ root: { display: 'inline-block' } }}
                                >
                                    {item.closureDate}
                                </TooltipHost> :
                                <div>
                                    {item.closureDate}
                                    {item.warningTrigger && <FontIcon iconName="WarningSolid" className={basicClasses.wrnIcon} />}
                                </div>
                            }
                        </span>
                    );
                }
            },
            {
                key: 'column5',
                name: "Rozmiar [GB]",
                fieldName: 'shareSizeRaw',
                minWidth: 65,
                maxWidth: 65,
                isRowHeader: true,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    return (
                        <span>{(Number(item.shareSize) / 1024).toFixed(0)}</span>
                    );
                }
            },
            {
                key: 'column6',
                name: "Użyte[%]",
                fieldName: 'shareUsageRawPrcnt',
                minWidth: 65,
                maxWidth: 65,
                isRowHeader: true,
                isResizable: false,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                onRender: (item: IShareItem) => {
                    if (Number(item.shareUsage) == Number(item.shareSize)) {
                        return (
                            <span>{item.shareUsageRawPrcnt.toFixed(2)}<FontIcon iconName="WarningSolid" className={basicClasses.errIcon} /></span>
                        );
                    } else if (Number(item.shareUsage) > Number(item.shareSize) * 0.8) {
                        return (
                            <span>{item.shareUsageRawPrcnt.toFixed(2)}<FontIcon iconName="WarningSolid" className={basicClasses.wrnIcon} /></span>
                        );
                    } else {
                        return (
                            <span>{item.shareUsageRawPrcnt.toFixed(2)}</span>
                        );
                    }
                }
            }

        ];

        const userColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'Typ',
                fieldName: 'userType',
                minWidth: 30,
                maxWidth: 30,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onUserColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    if (item.userType == "Pracownik NBP") {
                        return (
                            <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}>{"NBP"}</span>
                        );
                    } else {
                        return (
                            <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}><b>{"PZ"}</b></span>
                        );
                    }
                }
            },
            {
                key: 'column2',
                name: 'Imię',
                fieldName: 'firstName',
                minWidth: 70,
                maxWidth: 70,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onUserColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    //var shareT2 = this.getShareItemForselectedUser(item.groupId);
                    return (
                        <TooltipHost
                            content={item.groupId}
                        >
                            <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}>{item.firstName}</span>
                        </TooltipHost>
                    );
                }
            },
            {
                key: 'column3',
                name: 'Nazwisko',
                fieldName: 'lastName',
                minWidth: 100,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onUserColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    return (
                        <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}>{item.lastName}</span>
                    );
                }
            },
            {
                key: 'column4',
                name: 'UPN',
                fieldName: 'upn',
                minWidth: 255,
                maxWidth: 255,
                isRowHeader: true,
                isResizable: true,
                // isSorted: true,
                isSortedDescending: false,
                onColumnClick: this._onUserColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    return (
                        <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}>{item.upn}</span>
                    );
                }
            },
            {
                key: 'column5',
                name: 'Email',
                fieldName: 'email',
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isIconOnly: false,
                isResizable: false,
                onColumnClick: this._onUserColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    return (
                        <span onClick={() => { this.props.onFileShareClick("Admin request", item.groupId) }}>{item.email}</span>
                    );
                }
            },
            {
                key: 'column6',
                name: 'Alert',
                fieldName: 'alert',
                minWidth: 21,
                maxWidth: 21,
                isRowHeader: true,
                isIconOnly: false,
                isResizable: false,
                onColumnClick: this._onUserColumnClick,
                //data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    return (
                        <span>
                            {item.alert == "1" && <FontIcon iconName="Ringer" className={basicClasses.genericIcon} />}
                        </span>
                    );
                }
            },
            {
                key: 'column7',
                name: 'Operator',
                fieldName: 'isOperator',
                minWidth: 20,
                maxWidth: 20,
                isRowHeader: true,
                isIconOnly: false,
                isResizable: false,
                onColumnClick: this._onUserColumnClick,
                //data: 'string',
                isPadded: true,
                onRender: (item: IUserItem) => {
                    return (
                        <span>
                            {item.isOperator && <FontIcon iconName="Admin" className={basicClasses.genericIcon} />}
                        </span>
                    );
                }
            }
        ]

        this.state = {
            shares: this._shareItems,
            loadingState: false,
            loadingStateAdmin: false,
            loadingStateShareItems: false,
            showNewShareModal: false,
            showDirectAccessModal: false,
            loadingStateRequests: false,
            loadingStateUserItems: false,
            adminShareItems: [],
            adminUserItems: [],
            showAIPModal: false,
            itemCount: 30,
            lang: gblResourcePL,
            columns: columns,
            userColumns: userColumns,
            announcedMessage: "",
            searchBoxUserValue: "",
            searchBoxShareValue: "",
            notactiveCheckBox: false,
            aipCheckBox: false,
            exAccCheckBox: false,
            inAccCheckBox: false,
            directAccessRequests: [],
            showCleanModal: false
        };

        this.msalConfig = {
            auth: {
                clientId: config.appId,
                authority: config.authority
            }
        }
    }

    async componentDidMount() {

        //*** Language section ***
        if (this.props.lang == "PL") {
            this.setState({ lang: gblResourcePL })
        } else {
            this.setState({ lang: gblResource })
        }
        //*** end of language section ***

        //getting user groups:
        this._collectUserShares();

        //getting share requests:
        if (this.props.isAdmin || this.props.isOperator) {
            this._collectShareRequests();
        }

        //getting Direct Access requests:
        if (this.props.isAdmin) {
            this._collectDirectAccessRequests();
        }
    }

    render() {
        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = gblResourcePL;
        } else {
            resource = gblResource;
        }
        //*** end of language section ***



        const {
            columns,
            userColumns,
            shares = [],
            shareRequests = [],
            adminShareItems = [],
            adminUserItems = [],
            showNewShareModal,
            showAIPModal,
            currentItem,
            directAccessItem,
            deactivatedShares,
            aipShares,
            externalUsers,
            internalUsers,
            directAccessRequests,
            showDirectAccessModal,
            showCleanModal
        } = this.state;

        let resultCountText: string;

        //myShares
        if (Array.isArray(this._sharesFiltered)) {
            resultCountText = shares.length === this._sharesFiltered.length ? '' : ` (${shares.length} z ${this._sharesFiltered.length})`;
        } else {
            resultCountText = "";
        }

        return (
            <div className="overflow-auto">
                <Container className="mb-4">
                    <Row>
                        <Col>
                            <div className="card bg-swpStyle-light">
                                <div className="card-header text-center bg-swpStyle-dark swpStyle-CardHeader-Shares" style={{ display: "flex", flexFlow: "wrap" }}>
                                    <div className="swpStyle-newShare-buttonsLeft"></div>
                                    <h6 className="swp-CardHeader-Text">{resource.str_HeaderShareList}</h6>
                                    <div className="swpStyle-newShare-buttonsRight">
                                        <ShareListMenu
                                            isOperator={this.props.isOperator}
                                            isAdmin={this.props.isAdmin}
                                            refreshMethod={this._forceRefreshShareList.bind(this)}
                                            openNewShareForm={this._showNewShareModal.bind(this)}
                                            lang={this.props.lang}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Pivot theme={colorThemeLight} style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        <PivotItem headerText={resource.str_TabMyShares} className="swpStyle-Header-Text"//user shares - OFfice 365 groups from AAD
                                            headerButtonProps={{
                                                'data-order': 1,
                                                'data-title': 'My Shares Title'
                                            }}
                                        >
                                            {this.state.loadingState ? <LoadingSpinner lang={this.props.lang} /> :
                                                <div className="card-body">
                                                    <FocusZone direction={FocusZoneDirection.vertical}>
                                                        <TextField theme={colorThemeLight} className="mb-2" label={resource.str_PhSearch + resultCountText} onChange={this._onFilterChanged} />
                                                        <List items={shares} onRenderCell={this._onRenderCell.bind(this)} />
                                                    </FocusZone>
                                                </div>
                                            }
                                        </PivotItem>
                                        {this.props.isAdmin ?
                                            <PivotItem headerText={resource.str_TabAllShares} className="swpStyle-Header-Text"//All shares, for admin only, from SPO list
                                                headerButtonProps={{
                                                    'data-order': 3,
                                                    'data-title': 'Shares Items'
                                                }}
                                                itemCount={adminShareItems.length}
                                            >
                                                {this.state.loadingStateShareItems ? <LoadingSpinner lang={this.props.lang} /> :
                                                    <div className="card-body">
                                                        {this.props.isAdmin !== undefined ?
                                                            <div>
                                                                {adminShareItems.length == 0 ?
                                                                    <DefaultButton style={{ marginTop: '3px' }} text={resource.str_BtnRefreshAdminShares} onClick={this._forceRefreshShareList.bind(this)} /> :
                                                                    <div>
                                                                        <Stack horizontal styles={shareFilterStyles} tokens={stackTokens}>
                                                                            <Stack.Item styles={shareSearchBoxStyles}>
                                                                                <SearchBox styles={searchBoxStyle} placeholder={resource.str_LblSearchShare} onChange={this._onShareItemFilterChanged} value={this.state.searchBoxShareValue} />
                                                                            </Stack.Item>
                                                                            <Stack.Item styles={shareFiterItemStyles}>
                                                                                <Label>Obszary wyłączone: </Label>
                                                                                <Label style={{ fontWeight: 'bold' }}>{this.state.deactivatedShares}</Label>
                                                                            </Stack.Item>
                                                                            <Stack.Item styles={shareFiterItemStyles}>
                                                                                <Label>Obszary aktywne: </Label>
                                                                                <Label style={{ fontWeight: 'bold' }}>{this.state.activeShares}</Label>
                                                                            </Stack.Item>
                                                                            <Stack.Item styles={shareFiterItemStyles}>
                                                                                <Label>Obszary szyfrowane: </Label>
                                                                                <Label style={{ fontWeight: 'bold' }}>{this.state.aipShares}</Label>
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                        <Stack horizontal styles={shareFilterStyles} tokens={stackTokens}>
                                                                            <Checkbox label="Wyłączone" onChange={this._onNotActiveCheck} disabled={deactivatedShares == 0} />
                                                                            <Checkbox label="Szyfrowane" onChange={this._onAIPCheck} disabled={aipShares == 0} />
                                                                        </Stack>
                                                                        <div className={basicClasses.adminList}>
                                                                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ marginTop: '3px' }}>
                                                                                <DetailsList
                                                                                    items={adminShareItems}
                                                                                    compact={true}
                                                                                    columns={columns}
                                                                                    selectionMode={SelectionMode.none}
                                                                                    getKey={this._getKey}
                                                                                    setKey="none"
                                                                                    layoutMode={DetailsListLayoutMode.justified}
                                                                                    isHeaderVisible={true}
                                                                                    // usePageCache={true}
                                                                                    //selection={this._selection}
                                                                                    selectionPreservedOnEmptyClick={false}
                                                                                    theme={colorThemeLight}
                                                                                    onRenderRow={this._onRenderRow}
                                                                                    onRenderDetailsHeader={this._onRenderHeader}
                                                                                // onShouldVirtualize={() => false}
                                                                                />
                                                                            </ScrollablePane>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div> :
                                                            <p>{this.state.noAdminSharesLoaded}</p>
                                                        }
                                                    </div>
                                                }
                                            </PivotItem> :
                                            <div></div>
                                        }
                                        {this.props.isAdmin ?
                                            <PivotItem headerText={resource.str_TabAllUsers} className="swpStyle-Header-Text"//All share users, for admin only, from SPO list
                                                headerButtonProps={{
                                                    'data-order': 4,
                                                    'data-title': 'User Items'
                                                }}
                                                itemCount={adminUserItems.length}
                                            >
                                                {this.state.loadingStateUserItems ? <LoadingSpinner lang={this.props.lang} /> :
                                                    <div className="card-body">
                                                        {this.props.isAdmin !== undefined ?
                                                            <div>
                                                                {adminUserItems.length == 0 ?
                                                                    <DefaultButton style={{ marginTop: '3px' }} text={resource.str_BtnRefreshAdminUsers} onClick={this._forceRefreshShareList.bind(this)} /> :
                                                                    <div>
                                                                        <Stack horizontal styles={shareFilterStyles} tokens={stackTokens}>
                                                                            <Stack.Item styles={shareSearchBoxStyles}>
                                                                                <SearchBox styles={searchBoxStyle} placeholder={resource.str_LblSearchPerson} onChange={this._onUserItemFilterChanged} value={this.state.searchBoxUserValue} />
                                                                            </Stack.Item>
                                                                            <Stack.Item styles={shareFiterItemStyles}>
                                                                                <Label>Ilość podmiotów zewnętrznych: </Label>
                                                                                <Label style={{ fontWeight: 'bold' }}>{externalUsers}</Label>
                                                                            </Stack.Item>
                                                                            <Stack.Item styles={shareFiterItemStyles}>
                                                                                <Label>Ilość unikatowych pracowników: </Label>
                                                                                <Label style={{ fontWeight: 'bold' }}>{internalUsers}</Label>
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                        <Stack horizontal styles={shareFilterStyles} tokens={stackTokens}>
                                                                            <Checkbox label="Podmioty zewnętrzne" onChange={this._onExternalAccCheck} disabled={externalUsers == 0} />
                                                                            <Checkbox label="Pracownicy" onChange={this._onInternalAccCheck} disabled={internalUsers == 0} />
                                                                        </Stack>
                                                                        <div className={basicClasses.adminList}>
                                                                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ marginTop: '3px' }}>
                                                                                <DetailsList
                                                                                    items={adminUserItems}
                                                                                    compact={true}
                                                                                    columns={userColumns}
                                                                                    selectionMode={SelectionMode.none}
                                                                                    getKey={this._getKey}
                                                                                    setKey="none"
                                                                                    layoutMode={DetailsListLayoutMode.justified}
                                                                                    isHeaderVisible={true}
                                                                                    // usePageCache={true}
                                                                                    //selection={this._selection}
                                                                                    selectionPreservedOnEmptyClick={false}
                                                                                    theme={colorThemeLight}
                                                                                    onRenderRow={this._onRenderUserRow}
                                                                                    onRenderDetailsHeader={this._onRenderUserHeader}
                                                                                //onRenderItemColumn={this._onRenderItemColumn}
                                                                                // onShouldVirtualize={() => false}
                                                                                />
                                                                            </ScrollablePane>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div> :
                                                            <p>{"Nie załadowano użytkowników"}</p>
                                                        }
                                                    </div>
                                                }
                                            </PivotItem> :
                                            <div></div>
                                        }
                                        {this.props.isAdmin || this.props.isOperator ? //Share requests
                                            <PivotItem
                                                className="swpStyle-Header-Text"
                                                headerText={resource.str_TabShareRequests}
                                                headerButtonProps={{
                                                    'data-order': 5,
                                                    'data-title': 'In progress'
                                                }}
                                            >
                                                {this.state.loadingStateRequests ? <LoadingSpinner lang={this.props.lang} /> :
                                                    <div className="card-body">
                                                        <FocusZone direction={FocusZoneDirection.vertical}>
                                                            {shareRequests.length < 1 ?
                                                                <div><p>{resource.str_MsgNoShareRequests}</p></div> :
                                                                <List items={shareRequests} onRenderCell={this._onRenderStaticCell.bind(this)} className="share-list-items" />
                                                            }
                                                        </FocusZone>
                                                    </div>
                                                }
                                            </PivotItem> :
                                            <div></div>
                                        }
                                        {this.props.isAdmin ? //Direct Access requests
                                            <PivotItem
                                                className="swpStyle-Header-Text"
                                                headerText={resource.str_HeaderDARequests}
                                                headerButtonProps={{
                                                    'data-order': 5,
                                                    'data-title': 'In progress'
                                                }}
                                            > 
                                                {this.state.loadingStateRequests ? <LoadingSpinner lang={this.props.lang} /> :
                                                    <div className="card-body">
                                                        <FocusZone direction={FocusZoneDirection.vertical}>
                                                            {directAccessRequests.length < 1 ?
                                                                <div><p>{resource.str_MsgNoDirectAccessRequests}</p></div> :
                                                                <List items={directAccessRequests} onRenderCell={this._onRenderDirectAccessCell.bind(this)} className="share-list-items" />
                                                            }
                                                        </FocusZone>
                                                    </div>
                                                }
                                            </PivotItem> :
                                            <div></div>
                                        }
                                    </Pivot>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <NewShareForm
                    isOpen={showNewShareModal}
                    ref={this.newShareForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    lang={this.props.lang}
                    refreshAfterConfirmMethod={this._forceShareRequestsONLY.bind(this)}
                />
                <AIPConfirmForm
                    isOpen={showAIPModal}
                    ref={this.aipConfirmForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={currentItem}
                    shareDetailsRefreshMethod={null}
                    refreshAfterConfirmMethod={this._forceShareRequestsONLY.bind(this)}
                    message={resource.str_MsgIsAIPprocessFinishedConfirm}
                    lang={this.props.lang}
                />
                <CleanShareRequestForm
                    isOpen={showCleanModal}
                    ref={this.cleanShareRequestForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={currentItem}
                    refreshAfterConfirmMethod={this._forceShareRequestsONLY.bind(this)}
                    message={resource.str_MsgCleanShareRequestConfirm}
                    lang={this.props.lang}
                />
                <ManageDARequestForm
                    isOpen={showDirectAccessModal}
                    ref={this.manageDARequestForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    lang={this.props.lang}
                    currectRequestData={directAccessItem}
                    directAccessRefreshMethod={this._forceDirectAccessONLY.bind(this)}
                />

            </div>
        );
    }

    //#region General

    private _forceRefreshShareList() {
        this._collectUserShares();

        if (this.props.isAdmin || this.props.isOperator) { //RefreshData action triggered to limit all shares pull page load
            this._collectShareRequests();
        }

        if (this.props.isAdmin) {
            this._collectAdminShareItems();
            this._collectAdminUserItems();
            this._collectDirectAccessRequests();
        }

        this.setState({
            searchBoxShareValue: "",
            searchBoxUserValue: ""
        })
        this.forceUpdate();
    }

    private _forceShareRequestsONLY() {
        if (this.props.isAdmin || this.props.isOperator) { 
            this._collectShareRequests();
            this.setState({
                searchBoxShareValue: "",
                searchBoxUserValue: ""
            })
        }
    }

    private _forceDirectAccessONLY() {
        if (this.props.isAdmin) { 
            this._collectDirectAccessRequests();
            this.setState({
                searchBoxShareValue: "",
                searchBoxUserValue: ""
            })
        }
    }

    private _showAIPModal = (item: any): void => {
        config.DEBUG && console.log("!!! AIP Modal triggered ");
        this.setState({
            currentItem: item,
            showAIPModal: true
        });
        this.aipConfirmForm.current._openAIPConfirmModal();
    }

    private _showCleanModal = (item: any): void => {
        config.DEBUG && console.log("!!! Clean Modal triggered ");
        this.setState({
            currentItem: item,
            showCleanModal: true
        });
        this.cleanShareRequestForm.current._openCleanModal();
    }

    private _showNewShareModal = (): void => {
        config.DEBUG && console.log("!!! New Share Modal triggered ");
        this.setState({ showNewShareModal: true });
        this.newShareForm.current._openNewShareModal();
    };

    private _showDirectAccessModal = (item: IDirectAccessItem): void => {
        this.setState({ 
          directAccessItem: item,
          showDirectAccessModal: true 
        }, () => {
          if (this.manageDARequestForm.current) {
            this.manageDARequestForm.current._openDirectAccessRequestModal();
          } else {
            console.error("manageDARequestForm is not available");
          }
        });
      }

    //#endregion General

    //#region collecting shares and users

    async _collectAdminShareItems() {
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

        if (!this.state.loadingStateShareItems) {
            config.DEBUG && console.log("Loading adminShares START...") //trigger for spinner
            this.setState({ loadingStateShareItems: true });
        }

        await graphService.getAllShareListItems().then(
            result => {
                config.DEBUG && console.log(result.value)

                this._adminShareItems = new Array();
                this._adminShareItems = result.value.map(function (val: any) {

                    var now = new Date(moment().format())
                    var wrnTrigger = false
                    var closeTrigger = false
                    var wrnDate = addDays(val.fields.DataZamkniecia, -30)
                    var closeDate = addDays(val.fields.DataZamkniecia, 0)
                    var deleteDate = addDays(val.fields.DataZamkniecia, 30)

                    if (now >= wrnDate) {
                        var wrnTrigger = true
                        var closeTrigger = false
                    }
                    if (now > closeDate) {
                        var wrnTrigger = false
                        var closeTrigger = true
                    }

                    return {
                        shareName: val.fields.Title, //### Regional string
                        shareGroupId: val.fields.IdentyfikatorGrupy, //### Regional string
                        owner: val.fields.NazwaOperatora, //### Regional string
                        shareState: val.fields.StatusObszaru, //### Regional string
                        aipToggle: val.fields.DodatkoweZabezpieczenia, //### Regional string
                        created: val.createdDateTime,
                        shareType: val.fields.RodzajObszaru, //### Regional string
                        deactivated: val.fields.Wygaszony, //### Regional string
                        closureDateValue: val.fields.DataZamkniecia, //### Regional string
                        closureDate: moment(val.fields.DataZamkniecia).format("DD.MM.YYYY"),
                        shareSize: val.fields.WielkoscObszaru, //### Regional string
                        shareUsage: val.fields.ZuzyteZasoby, //### Regional string
                        shareSizeRaw: Number(val.fields.WielkoscObszaru), //### Regional string
                        shareUsageRaw: Number(val.fields.ZuzyteZasoby), //### Regional string
                        shareUsageRawPrcnt: ((100 * Number(val.fields.ZuzyteZasoby)) / Number(val.fields.WielkoscObszaru)),//### Regional string
                        warningTrigger: wrnTrigger,
                        closeTrigger: closeTrigger,
                        deleteDate: deleteDate

                    }
                });
            }
        )
            .catch(
                error => {
                    config.DEBUG && console.log(error);
                }
            );

        var sortedShares = this._adminShareItems.sort(function (a, b) {
            const valueA = a.created;
            const valueB = b.created;
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        });


        var deactivatedShares = this._adminShareItems.filter(filter => filter.deactivated != "0")
        var activeShares = this._adminShareItems.filter(filter => filter.deactivated == "0")
        var aipShares = this._adminShareItems.filter(filter => filter.aipToggle == true)

        this.setState({
            adminShareItems: sortedShares,
            deactivatedShares: deactivatedShares.length,
            aipShares: aipShares.length,
            activeShares: activeShares.length
        });

        if (this.state.loadingStateShareItems) {
            config.DEBUG && console.log("Loading adminShares STOP...") //stopping spinner
            this.setState({ loadingStateShareItems: false });
        }
    }

    async _collectAdminUserItems() {
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

        if (!this.state.loadingStateUserItems) {
            config.DEBUG && console.log("Loading admin Users START...") //trigger for spinner
            this.setState({ loadingStateUserItems: true });
        }

        await graphService.getAllUsersFromInvitations().then(
            result => {
                config.DEBUG && console.log(result.value)

                this._adminUserItems = new Array();
                this._adminUserItems = result.value.map(function (val: any) {


                    return {
                        shareName: val.fields.ShareName, //### Regional string
                        upn: val.fields.NazwaUPN, //### Regional string
                        email: val.fields.Email, //### Regional string
                        isOperator: val.fields.Operator, //### Regional string
                        userType: val.fields.Rodzaj, //### Regional string
                        firstName: val.fields.Imie, //### Regional string
                        lastName: val.fields.Nazwisko, //### Regional string
                        alert: val.fields.Alert, //### Regional string
                        groupId: val.fields.StatisGroupId, //### Regional string
                        created: val.createdDateTime

                    }
                });
            }
        )
            .catch(
                error => {
                    config.DEBUG && console.log(error);
                }
            );

        var externalUsers = this._adminUserItems.filter(filter => filter.userType != "Pracownik NBP")
        var internalUsers = this._adminUserItems.filter(filter => filter.userType == "Pracownik NBP")
        const uniqueInternalUsers = new Set(internalUsers.map(internalUser => internalUser.email))

        this.setState({
            adminUserItems: this._adminUserItems,
            externalUsers: externalUsers.length,
            internalUsers: Array.from(uniqueInternalUsers).length
        });

        if (this.state.loadingStateUserItems) {
            config.DEBUG && console.log("Loading admin Users STOP...") //stopping spinner
            this.setState({ loadingStateUserItems: false });
        }
    }

    private _filterAdminUserItems = (): void => {
        const { inAccCheckBox, exAccCheckBox } = this.state;
    
        let filteredItems = this._adminUserItems;
    
        if (inAccCheckBox && exAccCheckBox) {
            filteredItems = this._adminUserItems.filter(item => item.userType === "Pracownik NBP" || item.userType === "Podmiot zewnętrzny");
        } else if (inAccCheckBox) {
            filteredItems = this._adminUserItems.filter(item => item.userType === "Pracownik NBP");
        } else if (exAccCheckBox) {
            filteredItems = this._adminUserItems.filter(item => item.userType === "Podmiot zewnętrzny");
        }
    
        this.setState({ adminUserItems: filteredItems });
    }

    async _collectDirectAccessRequests() {
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
        var currentUser = this.props.userName
        if (!this.state.loadingStateRequests) {
            config.DEBUG && console.log("Loading directAccessRequests START...") //trigger for spinner
            this.setState({ loadingStateRequests: true });
        }

        await graphService.getDirectAccessRequests().then(
            async result => {
                this._directAccessRequests = new Array();

                for (let i = 0; i < result.value.length; i++) {
                    var item = result.value[i]
                    // if (item.createdBy.user.displayName == currentUser || this.props.isAdmin) {

                    // }
                    var simplifiedItem: IDirectAccessItem = {
                        shareName: item.fields.Title, //regional string
                        status: item.fields.Status, //regional string
                        shareURL: item.fields.AdresObszaru,
                        email: item.fields.OperatorEmail,
                        approveDate: item.fields.GodzinaZatwierdzenia,
                        justification: item.fields.Uzasadnienie,
                        requestItemID: item.id
                    }
                    this._directAccessRequests.push(simplifiedItem)
                }

                this.setState({ directAccessRequests: this._directAccessRequests });
                config.DEBUG && console.log(this._directAccessRequests)

                if (this.state.loadingStateRequests) {
                    config.DEBUG && console.log("Loading directAccessRequests STOP...") //stopping spinner
                    this.setState({ loadingStateRequests: false });
                }
            })
    }

    async _collectShareRequests() {
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
        var currentUser = this.props.userName
        if (!this.state.loadingStateRequests) {
            config.DEBUG && console.log("Loading shareRequests START...") //trigger for spinner
            this.setState({ loadingStateRequests: true });
        }

        await graphService.getShareRequestListItem().then(
            async result => {
                this._shareRequests = new Array();

                for (let i = 0; i < result.value.length; i++) {
                    var item = result.value[i]
                    if (item.createdBy.user.displayName == currentUser || this.props.isAdmin) {
                        var simplifiedItem: IShareItem = {
                            shareName: item.fields.Title, //regional string
                            aipToggle: item.fields.DodatkoweZabezpieczenia, //regional string
                            requestStatus: item.fields.StatusZadania, //regional string
                            shareItemId: item.id,
                            requestCreator: item.createdBy.user.displayName,
                            requestDate: item.fields.Created
                        }
                        this._shareRequests.push(simplifiedItem)
                    }
                }

                this.setState({ shareRequests: this._shareRequests });
                config.DEBUG && console.log(this._shareRequests)

                if (this.state.loadingStateRequests) {
                    config.DEBUG && console.log("Loading shareRequests STOP...") //stopping spinner
                    this.setState({ loadingStateRequests: false });
                }
            })
    }

    async _collectUserShares() {
        if (!this.state.loadingState) {
            config.DEBUG && console.log("Loading shares START...") //trigger for spinner
            this.setState({ loadingState: true });
        }

        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
        await graphService.getUserGroups().then(
            result => {
                let resultFiltered = result.value.filter((group: { description: any; }) => group.description === config.groupDescription);
                this._sharesFiltered = new Array();
                this._sharesFiltered = resultFiltered.map(function (val: any) {
                    return {
                        shareName: val.displayName,
                        shareGroupId: val.id,
                        shareSiteId: ""
                    }
                });
            }
        )
            .catch(
                error => {
                    config.DEBUG && console.log(error);
                }
            );


        this.setState({ shares: this._sharesFiltered });

        if (this.state.loadingState) {
            config.DEBUG && console.log("Loading shares STOP...") //stopping spinner
            this.setState({ loadingState: false });
        }
    }

    //#endregion collecting shares and users

    //#region rendering shares and users

    //rendering share list item

    private _onRenderRow = (props: IDetailsRowProps): JSX.Element => {

        //*** Language section ***
        let langfile: any;
        if (this.props.lang == "PL") {
            langfile = require("../LangFiles/resourcePL.json");
        } else {
            langfile = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const customStyles: Partial<IDetailsRowStyles> = {};

        customStyles.root = { backgroundColor: colorThemeLight.palette.neutralLight }

        if (props.item.shareState == "Odczyt") { //regional string
            customStyles.root = { backgroundColor: "gray", color: colorThemeLight.palette.black };
        }
        if (props.item.deactivated != "0") {
            customStyles.root = { backgroundColor: colorThemeDark.palette.themePrimary, color: colorThemeLight.palette.black };
        }

        return <DetailsRow {...props} styles={customStyles} />;
    };

    private _onRenderHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const customHeaderStyles: Partial<IDetailsHeaderStyles> = {};
        customHeaderStyles.root = { backgroundColor: colorThemeLight.palette.neutralLight, paddingTop: "1px" }

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                <DetailsHeader {...props} styles={customHeaderStyles} />
            </Sticky>
        );
    }

    //rendering user list item
    private _onRenderUserRow = (props: IDetailsRowProps): JSX.Element => {
        //config.DEBUG == true && console.log(props.item)

        //*** Language section ***
        let langfile: any;
        if (this.props.lang == "PL") {
            langfile = require("../LangFiles/resourcePL.json");
        } else {
            langfile = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const customStyles: Partial<IDetailsRowStyles> = {};

        customStyles.root = { backgroundColor: colorThemeLight.palette.neutralLight }

        if (props.item.isOperator == "Odczyt") { //regional settings
            customStyles.root = { backgroundColor: "gray", color: colorThemeLight.palette.black };
        }

        return (
            <DetailsRow {...props} styles={customStyles} />
        )

    };

    private _onRenderUserHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const customHeaderStyles: Partial<IDetailsHeaderStyles> = {};
        customHeaderStyles.root = { backgroundColor: colorThemeLight.palette.neutralLight, paddingTop: "1px" }

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                <DetailsHeader {...props} styles={customHeaderStyles} />
            </Sticky>
        );
    }

    //rendering my shares items 
    private _onRenderCell(item: IShareItem, index: number | undefined): JSX.Element {
        var currentShareItemBgClass = "share-list-even";
        if (index % 2 === 0) {
            currentShareItemBgClass = "share-list-even";
        } else {
            currentShareItemBgClass = "share-list-odd";
        }

        return (
            <div id={item.shareName} className={`${currentShareItemBgClass} ${classNames.itemCell}`} data-is-focusable={true} onClick={
                () => {
                    this.props.onFileShareClick(item.shareName, item.shareGroupId);
                }
            }>
                <div className={classNames.itemContent}>
                    <div className={classNames.itemName}>
                        <div>{item.shareName}</div>
                    </div>
                </div>
            </div>
        );
    }

    //rendering share requests
    private _onRenderStaticCell(item: IShareItem, index: number | undefined): JSX.Element { //used to render share requests

        //*** Language section ***
        let langfile: any;
        if (this.props.lang == "PL") {
            langfile = require("../LangFiles/resourcePL.json");
        } else {
            langfile = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        var currentShareItemBgClass = "share-list-even";
        if (index % 2 === 0) {
            currentShareItemBgClass = "share-list-even";
        } else {
            currentShareItemBgClass = "share-list-odd";
        }

        const locale = this.props.lang === 'PL' ? 'pl' : 'en';
        moment.locale(locale);
        const userFriendlyDate = moment(item.requestDate).format('LL');

        if (item != undefined) {
            return (
                <div id={item.shareName} className={`${currentShareItemBgClass} ${classNames.itemStaticCell}`} data-is-focusable={false}>
                    <div className={classNames.requestItemContent}>
                        <div style={{ alignItems: 'center' }} className={classNames.itemName}>{item.shareName}</div>
                        {item.aipToggle ?
                            <FontIcon iconName="LockSolid" className={`${classNames.itemName} ${basicClasses.genericIcon}`} /> :
                            <div></div>
                        }

                        <TextField className={classNames.itemName} styles={{ root: { marginLeft: 'auto' } }} disabled defaultValue={userFriendlyDate} />

                        {/* {this.props.isAdmin && item.requestStatus == gblResourcePL.str_FieldValueLabelInProgress && item.aipToggle ? */}
                        {this.props.isAdmin && item.requestStatus == "Przygotowanie etykiety" && item.aipToggle ?
                            <PrimaryButton className={classNames.itemName} styles={{ root: { marginLeft: 'auto' } }} onClick={
                                () => {
                                    this._showAIPModal(item)
                                }
                            }>{langfile.str_BtnContinueAIP}
                            </PrimaryButton> :
                            <div></div>
                        }

                        {this.props.isAdmin && item.requestStatus != "Ukończone poprawnie" ?
                            <DefaultButton className={classNames.itemName} styles={{ root: { marginLeft: 'auto' } }} onClick={
                                () => {
                                    this._showCleanModal(item)
                                }
                            }>{langfile.str_BtnCleanRequest}
                            </DefaultButton> :
                            <div></div>
                        }
                    </div>
                </div>
            );
        }
    }

    //rendering direct access requests
    private _onRenderDirectAccessCell(item: IDirectAccessItem, index: number | undefined): JSX.Element {

        //*** Language section ***
        let langfile: any;
        if (this.props.lang == "PL") {
            langfile = require("../LangFiles/resourcePL.json");
        } else {
            langfile = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        var currentShareItemBgClass = "share-list-even";
        if (index % 2 === 0) {
            currentShareItemBgClass = "share-list-even";
        } else {
            currentShareItemBgClass = "share-list-odd";
        }
        
        if (item != undefined) {

            var statusNotNew = item.status != "Nowy";
            if(item.status === "Zatwierdzony") {
                var statusText = item.status + " (" + item.approveDate + ")"
            } else {
                var statusText = item.status
            }

            return (
                <div id={item.shareName} className={`${currentShareItemBgClass} ${classNames.itemStaticCell}`} data-is-focusable={false}>
                    <div className={classNames.requestItemContent} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ alignItems: 'center' }} className={classNames.itemName}>{item.shareName}</div>
                        <div style={{ alignItems: 'center' }} className={classNames.itemName}>{statusText}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                            <PrimaryButton className={classNames.itemName} styles={{ root: { marginLeft: 'auto' } }} disabled={statusNotNew} onClick={
                                () => {
                                    this._showDirectAccessModal(item)
                                }
                            }>{"Zarządzaj"}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            );
        }
    }

    //#endregion rendering shares and users

    //#region User Actions

    // private _onShareRequestClick = () => {
    //     config.DEBUG && console.log("Share Request clicked");
    //     //code to fire confirmation modal continueShareRequest
    // }

    private _onFilterChanged = (_: any, text: string): void => {
        this.setState({
            shares: text ? this._sharesFiltered.filter(item => item.shareName.toLowerCase().indexOf(text.toLowerCase()) >= 0) : this._sharesFiltered
        });
    };

    private _onShareItemFilterChanged = (_: any, text: string): void => {
        config.DEBUG && console.log(text)
        // var tempShares = this.state.adminShareItems
        this.setState({
            searchBoxShareValue: text,
            adminShareItems:
                text && (this.state.adminShareItems != undefined) ?
                    this.state.adminShareItems.filter(item =>
                        item.shareName.toLowerCase().indexOf(text.toLowerCase()) >= 0
                        || item.owner.toLowerCase().indexOf(text.toLowerCase()) >= 0
                    ) :
                    this._adminShareItems
        });
    };

    private _onUserItemFilterChanged = (_: any, text: string): void => {
        config.DEBUG && console.log(text)
        this.setState({
            searchBoxUserValue: text,
            adminUserItems:
                text && (this._adminUserItems != undefined) ?
                    this._adminUserItems.filter(item =>
                        item.email.toLowerCase().includes(text.toLowerCase()) == true
                        || item.upn.toLowerCase().includes(text.toLowerCase()) == true
                        //|| item.lastName.toLowerCase().includes(text.toLowerCase()) == true
                    ) :
                    this._adminUserItems
        });
    };

    private _onNotActiveCheck = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (isChecked) {
            this.setState({
                notactiveCheckBox: true,
                adminShareItems:
                    this.state.adminShareItems.filter(item =>
                        item.deactivated != "0"
                    )
            })
        } else {
            if (!this.state.aipCheckBox) {
                this.setState({
                    notactiveCheckBox: false,
                    adminShareItems:
                        this._adminShareItems.filter(item =>
                            item.deactivated == "1" || item.deactivated == "0"
                        )
                })
            } else {
                this.setState({
                    notactiveCheckBox: false,
                    adminShareItems:
                        this._adminShareItems.filter(item =>
                            (item.deactivated == "1" || item.deactivated == "0") &&
                            item.aipToggle == true
                        )
                })
            }

        }
    }

    private _onAIPCheck = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (isChecked) {
            this.setState({
                aipCheckBox: true,
                adminShareItems:
                    this.state.adminShareItems.filter(item =>
                        item.aipToggle == isChecked
                    )
            })
        } else {
            if (!this.state.notactiveCheckBox) { //other filter NOT CHECKED
                this.setState({
                    aipCheckBox: false,
                    adminShareItems:
                        this._adminShareItems.filter(item =>
                            (item.aipToggle == true || item.aipToggle == false)
                        )
                })
            } else { //other filter CHECKED
                this.setState({
                    aipCheckBox: false,
                    adminShareItems:
                        this._adminShareItems.filter(item =>
                            (item.aipToggle == true || item.aipToggle == false) &&
                            item.deactivated != "0"
                        )
                })
            }

        }
    }

    private _onExternalAccCheck = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        this.setState({ exAccCheckBox: isChecked }, this._filterAdminUserItems);
    }
    
    private _onInternalAccCheck = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        this.setState({ inAccCheckBox: isChecked }, this._filterAdminUserItems);
    }


    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, adminShareItems } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                // this.setState({
                //     announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
                // });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(adminShareItems, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            adminShareItems: newItems
        });
    };

    private _onUserColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { userColumns, adminUserItems } = this.state;
        const newColumns: IColumn[] = userColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                // this.setState({
                //     announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
                // });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(adminUserItems, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            userColumns: newColumns,
            adminUserItems: newItems
        });
    };

    private _onUserHoover = () => {
        //method to show share data for particualar user via tooltip (hoover)
        //to use getShareListItem graph metod passing group id
        //getShareListItem
        //var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }

    //#endregion User Actions
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

function addDays(date: string, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};