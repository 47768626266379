import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as Msal from "msal";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { GraphService } from "./GraphService";
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { } from 'office-ui-fabric-react/lib/Icon';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { getFileTypeIconProps, FileIconType, initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { Stack, IStackStyles, CommandButton, IIconProps } from 'office-ui-fabric-react';
import * as moment from 'moment';
import { LoadingSpinner } from "./LoadingSpinner";
import { colorThemeLight } from './Themes';



initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/");
initializeFileTypeIcons();

const config = require("./config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

//Styles
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const downloadIcon: IIconProps = { iconName: 'Download' };

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px'
  },
  fileIconCell: {
    textAlign: 'center',
    height: '20px',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden'
      }
    }
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    //minHeight: '20px',
    maxWidth: '16px'
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px'
  },
  selectionDetails: {
    marginBottom: '10px',
    marginTop: '10px'
  }
});

const commandBarStyles: Partial<IStackStyles> = { root: { paddingLeft: '0px' } };

// interface IMessageBarProps {
//   fileName?: string;
//   closeMsg?: () => void;
// }

export interface IDocument {
  key: string;
  name: string;
  value: string;
  iconName: string;
  fileType: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  downloadUrl: string;
  webUrl: string;
  isFolder: boolean;
  itemId: string;
}

export interface ManualBrowserProps {
  shareName: string,
  shareGroupId: string,
  shareSiteId: string,
  backLinkMethod: any,
  authToken: string,
  lang: string
};
export interface ManualBrowserState { 
    visible:boolean;
    shareName:string;
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    announcedMessage?: string; 
    driveId?: string;
    loadingState: boolean;
    selectedFiles?: IDocument[];
    multiFile: boolean;
    downloadButtonDisabled: boolean;
    currentFolderId?:any;
};

export class ManualBrowser extends React.Component<ManualBrowserProps, ManualBrowserState> {
    private msalConfig: Msal.Configuration;
    private userAgentApplication: Msal.UserAgentApplication;
    private _selection: Selection;
    private _allItems: IDocument[];
    private driveId: string;
    private folderPath = new Array<string>();

    constructor(props: ManualBrowserProps) {
      super(props);

      this.msalConfig = {
          auth: {
              clientId: config.appId,
              authority: config.authority
          }
      }

      this.forceRefreshFileList.bind(this);

    this._allItems = [];

    this.userAgentApplication = new Msal.UserAgentApplication(this.msalConfig);

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Typ',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          //return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
          if (item.fileType == "folder")
            return (
              <Icon className="swpStyle-file-browser-icon" {...getFileTypeIconProps({ type: FileIconType.folder, size: 16, imageFileType: 'svg' })} />
            );
          else
            return (
              <a href={item.webUrl} target="_blank">
                <Icon className="swpStyle-file-browser-icon" {...getFileTypeIconProps({ extension: item.fileType, size: 16, imageFileType: 'svg' })} />
              </a>
            );
        }
      },
      {
        key: 'column2',
        name: 'Nazwa',
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          // if (item.isFolder) {
          //   return <a id={item.itemId} data-name={item.name} className="swpStyle-file-download-link" onClick={this._onFolderClick.bind(this)}>{item.name}</a>
          // } else {
            return <a href={item.webUrl} className="swpStyle-file-download-link">{item.name}</a>
          // }
        }
      }
      
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails()
        });
    }
  });

    this.state = {
      visible:false,
      shareName: "",
      items: this._allItems,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: false,
      isCompactMode: false,
      announcedMessage: undefined,
      loadingState:false,
      multiFile: false,
      downloadButtonDisabled: true
    }
  }

    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { columns, items, downloadButtonDisabled  } = this.state;
        
        return (
            <Container>
                <Row>
                    <Col>
                      <Breadcrumb bsPrefix="breadcrumbMenu breadcrumb">
                        <Breadcrumb.Item style={{color:'black'}} onClick={this.props.backLinkMethod}>{resource.str_HomePageLinkName}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{resource.str_ManualsShareName}</Breadcrumb.Item>
                      </Breadcrumb>
                      <Fabric>
                      <Stack horizontal styles={stackStyles} wrap={true}>
                        <CommandButton iconProps={downloadIcon} text={resource.str_BtnDownload} disabled={downloadButtonDisabled} onClick={this._onDownloadItemClick.bind(this)} />
                      </Stack>
                        {this.state.loadingState ? <LoadingSpinner lang={this.props.lang}/> :
                          <MarqueeSelection selection={this._selection} theme={colorThemeLight}>                          
                            <DetailsList
                            items={items}
                            compact={true}
                            columns={columns}
                            selectionMode={SelectionMode.single}
                            getKey={this._getKey}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={false}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            />
                        </MarqueeSelection>
                      }
                    </Fabric>
                    </Col>
                </Row>
            </Container>
        );
    }


  private async _onDownloadItemClick() { //this method will compress files to zip if more than one selected  
    config.DEBUG && console.log(this.state.selectedFiles)   
    window.open(this.state.selectedFiles[0].downloadUrl)
    this.setState({selectedFiles: null});
  }

  public forceRefreshFileList() {
    this.setState(
      {
        items: new Array()
      }
    )
    this.getDocuments(this.state.driveId, this.state.currentFolderId).then(
      result => {
        //const {items} = this.state;
        //this._allItems = items;
        this.setState(
        {
          items: this._allItems
        });
      }
    );
  }
  
  public componentDidUpdate(previousProps: any, previousState: ManualBrowserState) {
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
  }

  async getDriveId() {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    var driveIds = await graphService.getDriveId(this.props.shareSiteId);
    this.driveId = driveIds.value[0].id;
  }

  async getDocuments(driveId?: string, folderId?: string) {
    //const items: IDocument[] = [];
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    let documents: IDocument[];

    if (!this.state.loadingState) {
      config.DEBUG && console.log("Loading files START...") //trigger for spinner
      this.setState({ loadingState: true });
    }

    if (driveId && folderId) {
      await graphService.getShareFiles(this.props.shareSiteId, driveId, folderId).then(
        result => {
          config.DEBUG && console.log("folder: " + result);
          documents = result.value.map((val: any, index: number) => {
            var fileType = "";
            var downloadUrl = "";
            var webUrl = "";
            var isFolder = false;
            var lastModifiedBy = "";

            if (val.folder) {
              config.DEBUG && console.log(val.name + " is folder");
              fileType = "folder";
              downloadUrl = "";
              webUrl = val.webUrl;
              isFolder = true;
            } else {
              fileType = _getDocTypeFromFileName(val.name);
              downloadUrl = val['@microsoft.graph.downloadUrl'];
              webUrl = val.webUrl;
              isFolder = false;
            }

            if (val.lastModifiedBy.user) {
              lastModifiedBy = val.lastModifiedBy.user.displayName;
            } else {
              lastModifiedBy = val.lastModifiedBy.application.displayName;
            }

            return {
              key: index.toString(),
              name: val.name,
              value: val.name,
              iconName: _getFileIcon(_getDocTypeFromFileName(val.name)).url,
              fileType: fileType,
              //fileType: "docx",
              modifiedBy: lastModifiedBy,
              dateModified: moment(val.lastModifiedDateTime).format("DD.M.YYYY hh:mm"),
              dateModifiedValue: val.lastModifiedDateTime,
              fileSize: _getFileSize(val.size).value,
              fileSizeRaw: _getFileSize(val.size).rawSize,
              downloadUrl: downloadUrl,
              webUrl: webUrl,
              isFolder: isFolder,
              itemId: val.id
            }
          })
        }
      )
    } else {
      await graphService.getShareFiles(this.props.shareSiteId).then(
        result => {
          config.DEBUG && console.log(result);
          documents = result.value.map((val: any, index: number) => {
            var fileType = "";
            var downloadUrl = "";
            var webUrl = "";
            var isFolder = false;
            var lastModifiedBy = "";

            if (val.folder) {
              config.DEBUG && console.log(val.name + " is folder");
              fileType = "folder";
              downloadUrl = "";
              webUrl = val.webUrl;
              isFolder = true;
            } else {
              fileType = _getDocTypeFromFileName(val.name);
              downloadUrl = val['@microsoft.graph.downloadUrl'];
              webUrl = val.webUrl;
              isFolder = false;
            }

            if (val.lastModifiedBy.user) {
              lastModifiedBy = val.lastModifiedBy.user.displayName;
            } else {
              lastModifiedBy = val.lastModifiedBy.application.displayName;
            }
            return {
              key: index.toString(),
              name: val.name,
              value: val.name,
              iconName: _getFileIcon(_getDocTypeFromFileName(val.name)).url,
              fileType: fileType,
              //fileType: "docx",
              modifiedBy: lastModifiedBy,
              dateModified: moment(val.lastModifiedDateTime).format("DD.M.YYYY hh:mm"),
              dateModifiedValue: val.lastModifiedDateTime,
              fileSize: _getFileSize(val.size).value,
              fileSizeRaw: _getFileSize(val.size).rawSize,
              downloadUrl: downloadUrl,
              webUrl: webUrl,
              isFolder: isFolder,
              itemId: val.id
            }
          })
        });
    }


    this._allItems = documents;

    if (this.state.loadingState) {
      config.DEBUG && console.log("Loading files STOP...") //stopping spinner
      this.setState({ loadingState: false });
    }
    //config.DEBUG && console.log(documents);
  }

  async componentDidMount() {
    config.DEBUG && console.log("ManualBrowser mounted");
    config.DEBUG && console.log("Props: " + this.props.shareSiteId + " " + this.props.shareGroupId + " " + this.props.shareName);

    await this.getDriveId();
    await this.getDocuments();

    this.setState({
      items: this._allItems,
      driveId: this.driveId
    })
  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    this._selection.getSelection();

    switch (selectionCount) {
      case 0:
        this.setState({downloadButtonDisabled: true, selectedFiles: null});
        return this.props.lang == "PL" ? gblResourcePL.str_MsgNoItemsSelected : gblResource.str_MsgNoItemsSelected;
      case 1:
        let selectedFile:IDocument[] = this._selection.getSelection() as IDocument[];
        this.setState({downloadButtonDisabled: false, selectedFiles:selectedFile});
        return this.props.lang == "PL" ? gblResourcePL.str_MsgOneItemSelected : gblResource.str_MsgOneItemSelected + (this._selection.getSelection()[0] as IDocument).name;
      default:
        let selectedFiles:IDocument[] = this._selection.getSelection() as IDocument[];
        this.setState({downloadButtonDisabled: true, selectedFiles: selectedFiles});
        return `${this.props.lang == "PL" ? gblResourcePL.str_MsgSelectedItemsCount : gblResource.str_MsgSelectedItemsCount} ${selectionCount}`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems
    });
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _getFileIcon(docType: string): { docType: string; url: string } {
  //const docType: string = FILE_ICONS[Math.floor(Math.random() * FILE_ICONS.length)].name;
  return {
    docType,
    url: `https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/${docType}_16x1.svg`
  };
}

function _getDocTypeFromFileName(fileName: string) {
  var fileParts = fileName.split(".");
  if (fileParts.length > 1) {
    return fileParts[fileParts.length - 1];
  } else {
    return "txt";
  }
}

function _getFileSize(fileSize: number): { value: string; rawSize: number } {
  //const fileSize: number = Math.floor(Math.random() * 100) + 30;
  var fileSizeKB = (fileSize / 1024);
  var fileSizeMB = fileSizeKB / 1024;
  if (fileSizeKB > 1000) {
    return {
      value: `${fileSizeMB.toFixed(1)} MB`,
      rawSize: fileSize
    };
  }
  else {
    return {
      value: `${fileSizeKB.toFixed(0)} KB`,
      rawSize: fileSize
    }
  }
}

